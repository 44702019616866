import React, { useContext, useEffect, useState } from 'react'
import { useGetX4FromSession } from 'src/helpers/hooks/useGetX4FromSession'
import { PATH_URL } from 'src/helpers'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../contextAPI'

export const QuizProgressBar = () => {
  const x4 = useGetX4FromSession()
  const { screen, quizProgressPercent, setQuizProgressPercent } = useContext(QuizQuestionContext)
  const [percentage, setPercentage] = useState(quizProgressPercent)

  const setPercentageForScreenForX4 = (screen: SCREEN_QUIZ_QUESTION | undefined) => {
    switch (screen) {
      case SCREEN_QUIZ_QUESTION.PERSONAL_DETAILS:
        setPercentage(20)
        setQuizProgressPercent(20)
        break
      case SCREEN_QUIZ_QUESTION.WEDDING_DETAILS:
        setPercentage(40)
        setQuizProgressPercent(40)
        break
      case SCREEN_QUIZ_QUESTION.YOU_BOOKED:
        setPercentage(60)
        setQuizProgressPercent(60)
        break
      case SCREEN_QUIZ_QUESTION.BUDGET:
        setPercentage(80)
        setQuizProgressPercent(80)
        break
      default:
        setPercentage(100)
        setQuizProgressPercent(100)
        break
    }
  }

  const setPercentageForScreen = (screen: SCREEN_QUIZ_QUESTION | undefined) => {
    switch (screen) {
      case SCREEN_QUIZ_QUESTION.WEDDING_DATE:
        setPercentage(25)
        setQuizProgressPercent(25)
        break
      case SCREEN_QUIZ_QUESTION.YOU_BOOKED:
        setPercentage(50)
        setQuizProgressPercent(50)
        break
      case SCREEN_QUIZ_QUESTION.BUDGET:
        setPercentage(75)
        setQuizProgressPercent(75)
        break
      default:
        setPercentage(100)
        setQuizProgressPercent(100)
        break
    }
  }

  const setPercentageForPathForX4 = (pathname: string) => {
    switch (pathname) {
      case PATH_URL.PERSONAL_DETAIL:
        setPercentage(20)
        setQuizProgressPercent(20)
        break
      case PATH_URL.WEDDING_DETAIL:
        setPercentage(40)
        setQuizProgressPercent(40)
        break
      case PATH_URL.AVAILABILITY_PAGE:
        setPercentage(60)
        setQuizProgressPercent(60)
        break
      case PATH_URL.BUDGET_PAGE:
        setPercentage(80)
        setQuizProgressPercent(80)
        break
      default:
        setPercentage(100)
        setQuizProgressPercent(100)
        break
    }
  }

  const setPercentageForPath = (pathname: string) => {
    switch (pathname) {
      case PATH_URL.DETAILS:
        setPercentage(25)
        setQuizProgressPercent(25)
        break
      case PATH_URL.AVAILABILITY_PAGE:
        setPercentage(50)
        setQuizProgressPercent(50)
        break
      case PATH_URL.BUDGET_PAGE:
        setPercentage(75)
        setQuizProgressPercent(75)
        break
      default:
        setPercentage(100)
        setQuizProgressPercent(100)
        break
    }
  }

  useEffect(() => {
    if (x4) {
      setPercentageForScreenForX4(screen)
    } else {
      setPercentageForScreen(screen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen, x4])

  useEffect(() => {
    if (x4) {
      setPercentageForPathForX4(location.pathname)
    } else {
      setPercentageForPath(location.pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <div className={x4 ? 'quiz-progress-bar-green' : 'quiz-progress-bar'}>
        <div className="progress-percentage" style={{ width: `${percentage}%` }}></div>
      </div>
    </>
  )
}
