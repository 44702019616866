import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { AssetPreload } from './components'
import { QuizQuestionContext, useQuizQuestionContextValue } from './contextAPI'
import { PATH_URL } from 'src/helpers'
import { QuizQuestionPage } from './pages/QuizQuestionPage'
import { PersonalDetails } from './pages/PersonalDetails'
import { WeddingDetails } from './pages/WeddingDetails'
import { AvailabilityPage } from './pages/AvailabilityPage'
import { BudgetPage } from './pages/BudgetPage'
import { ColorsFlowersPage } from './pages/ColorsFlowersPage'
import { WeddingDate } from './pages/WeddingDate'
import { MetBefore } from './components/MetBefore/MetBefore'
import { EndQuiz } from './components/EndQuiz'
import { MetBeforeAfterSubmitted } from './components/MetBeforeAfterSubmitted/MetBeforeAfterSubmitted'

export const QuizQuestion: React.FC = () => {
  const quizQuestionContextValue = useQuizQuestionContextValue()
  return (
    <QuizQuestionContext.Provider value={quizQuestionContextValue}>
      <AssetPreload>
        <Switch>
          <Route path={PATH_URL.QUIZ_QUESTION} component={QuizQuestionPage} />
          <Route path={PATH_URL.DETAILS} component={WeddingDate} />
          <Route path={PATH_URL.PERSONAL_DETAIL} component={PersonalDetails} />
          <Route path={PATH_URL.WEDDING_DETAIL} component={WeddingDetails} />
          <Route path={PATH_URL.AVAILABILITY_PAGE} component={AvailabilityPage} />
          <Route path={PATH_URL.BUDGET_PAGE} component={BudgetPage} />
          <Route path={PATH_URL.COLOR_PALETTE_PAGE} component={ColorsFlowersPage} />
          <Route path={PATH_URL.EXISTING_PROPOSAL_AFTER_SUBMITTED} component={MetBeforeAfterSubmitted} />
          <Route path={PATH_URL.EXISTING_PROPOSAL} component={MetBefore} />
          <Route path={PATH_URL.QUIZ_END} component={EndQuiz} />
        </Switch>
      </AssetPreload>
    </QuizQuestionContext.Provider>
  )
}
