import React, { PropsWithChildren, useRef } from 'react'
import { ButtonWithIcon, PrevArrowIcon } from 'src/common'

// import { Progressbar } from 'src/common/components/progressbar'
import { PATH_URL, REDIRECT_PATH } from 'src/helpers'
import { Header } from 'src/pages/results/Header'
import { QuizProgressBar } from './QuizProgressBar'
import { SESSION_NAME_LOCAL } from 'src/helpers/app'

export interface IAction {
  disabled?: boolean
  onAction?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  hidden?: boolean
}

export type IQuizQuestionWrapperProps = PropsWithChildren<{
  titleStep?: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
  extraText?: string
  customCss?: Partial<{
    content?: string
    title?: string
  }>
  backAction?: IAction
  nextAction?: IAction
  step?: number
  total?: number
  className?: string
  showBgFlower?: boolean
  noHeaderTop?: boolean
}>

export const QuizQuestionWrapper: React.FC<IQuizQuestionWrapperProps> = (props: IQuizQuestionWrapperProps) => {
  const {
    titleStep,
    title,
    subtitle,
    customCss,
    backAction,
    children,
    extraText,
    showBgFlower = true,
    step,
    total,
    noHeaderTop,
  } = props
  const checkLastFocusRef = useRef<HTMLDivElement>(null)
  const nextButtonRef = useRef<HTMLButtonElement>(null)

  // Focus on the next button if the question has been completed
  const handleTrigger = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Tab' && checkLastFocusRef.current?.contains(event.target as Node)) {
      nextButtonRef.current?.focus()
    }
  }

  const handleExit = () => {
    console.log('exit quiz')

    // remove session_id after user exit => allow user can take the other quiz with new email
    localStorage.setItem(SESSION_NAME_LOCAL, '')

    if (window.history.length > 1) {
      // window.history.back()
      window.location.href = PATH_URL.SWIPING
    } else {
      window.location.href = REDIRECT_PATH.HOME
    }
  }

  return (
    <div className={`quiz-question-wrapper ${props.className}`} onKeyUp={handleTrigger}>
      {!noHeaderTop && (
        <div className="header-top">
          <Header />
          {titleStep !== 'Guinea pig' && (
            <div className="content">
              <div className="action left-action">
                <ButtonWithIcon
                  ref={nextButtonRef}
                  disabled={backAction?.disabled}
                  onClick={backAction?.onAction && backAction.onAction}
                  className="back-action"
                >
                  {!backAction?.disabled && (
                    <>
                      <PrevArrowIcon color="black" stroke="black" />
                      <span>BACK</span>
                    </>
                  )}
                </ButtonWithIcon>
              </div>
              <div className="step-title-box">
                <h1 className="text-title">
                  {titleStep} {step && total ? `${step}/${total}` : ''}
                </h1>
              </div>
              <div className="action right-action">
                <ButtonWithIcon onClick={handleExit} className="next-action">
                  <span>EXIT</span>
                </ButtonWithIcon>
              </div>
            </div>
          )}
          {/* <div className="mobile">
          <Progressbar progress={(step / total) * 100} />
        </div> */}

          <QuizProgressBar />
        </div>
      )}
      <div className={titleStep === 'Guinea pig' ? 'quiz-question-content guinea-pig-custom' : 'quiz-question-content'}>
        <div className={titleStep === 'Guinea pig' ? 'body-section w-100 pl-0px pr-0px' : 'body-section'}>
          {title && (
            <div className="title-section">
              <h1 className="text-detail">{title}</h1>
              <p className="step-subtitle">{subtitle}</p>
              {extraText && <p className="step-subtitle mt-10px" dangerouslySetInnerHTML={{ __html: extraText }} />}
            </div>
          )}
          <div
            className={`quiz-content justify-content-center d-flex font-color ${customCss?.content ?? ''} ${
              titleStep === 'Guinea pig' ? 'w-100' : ''
            }`}
          >
            {children}
          </div>
          <div ref={checkLastFocusRef} tabIndex={0} />
        </div>
        {showBgFlower && titleStep !== 'Guinea pig' && (
          <>
            <div className="left-flower"></div>
            <div className="right-flower"></div>
          </>
        )}
      </div>
    </div>
  )
}
