/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { API, BudgetFocus, IOption, ISubmitQuizQuestionPayload, ISubmitQuizQuestionResponse } from 'src/types'
import { AppContext } from 'src/helpers/app'
import { postHttp } from 'src/api'
import { EVENTS_FB_PIXEL, PATH_API, fbEvent, getQueryParameters } from 'src/helpers'
import { ACTION_APP, ga4EventApp, gaEventApp } from 'src/helpers/googleAnalyticsService'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'
import { ENV } from 'src/config/env'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'
import { useHistory } from 'react-router-dom'
import { NP_EXPERIMENT } from 'src/pages/shared'
import { vwoEvent } from 'src/helpers/vwo'

const AMOUNT_STEP = 250
const MIN_AMOUNT = 1750
const MAX_AMOUNT = 7500
export const MAX_TIME_TRACKING = 1500
const STARTING_AMOUNT = 3000

const mappingFocusLabel = (focus: BudgetFocus) => {
  switch (focus) {
    case BudgetFocus.EQUAL:
      return 'Equal emphasis on ceremony and reception'
    case BudgetFocus.CEREMONY:
      return 'More emphasis on ceremony'
    case BudgetFocus.RECEPTION:
      return 'More emphasis on reception'
    default:
      return '--'
  }
}

const BUDGET_FOCUS: IOption[] = [
  {
    value: BudgetFocus.EQUAL,
    label: 'Equal emphasis on ceremony and reception',
  },
  {
    value: BudgetFocus.CEREMONY,
    label: 'More emphasis on ceremony',
  },
  {
    value: BudgetFocus.RECEPTION,
    label: 'More emphasis on reception',
  },
]

type Budget = {
  amount: number | string
  focus: BudgetFocus | string
  images: string[]
  label: string
}

const submitQuizResponseAPI = (submitData: ISubmitQuizQuestionPayload) => {
  return postHttp(PATH_API.postSubmit, submitData)
}

const gAdwords = (param: any) =>
  new Promise((rs) => {
    const report = (window as { [key: string]: any })['gtag_report_conversion']
    // We report adwords conversions by inserting a function into the <head> of the page.
    if (report) {
      report({ ...param, event_callback: rs })
      setTimeout(rs, MAX_TIME_TRACKING)
    } else {
      rs(true)
    }
  })
const ga4Event = (action: ACTION_APP, params: any = {}) =>
  new Promise((rs) => {
    ga4EventApp(action, { ...params, event_callback: rs })
    setTimeout(rs, MAX_TIME_TRACKING)
  })
const _segmentTracking = (action: string) =>
  new Promise((rs) => {
    segmentTracking(action, {}, {}, rs)
    setTimeout(rs, MAX_TIME_TRACKING)
  })
const pintrk = () =>
  new Promise((rs) => {
    const windowGlobal = window as any
    if (windowGlobal.pintrk) {
      windowGlobal.pintrk('track', 'lead', {}, function (didInit: boolean, error: any) {
        if (!didInit) {
          console.log(error)
        }
        rs(true)
      })
    }
    setTimeout(rs, MAX_TIME_TRACKING)
  })
// Cheat: timeout for lib don't support callback
export const funcNoCallback = (func: () => void) =>
  new Promise((rs) => {
    func()
    setTimeout(rs, MAX_TIME_TRACKING)
  })

export const useBudget = () => {
  const { sessionID, storeURLParameters, handleChangeAppData } = useContext(AppContext)
  const { quizQuestionData, setQuizScreen, setQuizData, data } = useContext(QuizQuestionContext)
  const [budget, setBudget] = useState<Budget>({
    amount: STARTING_AMOUNT,
    focus: BudgetFocus.EQUAL,
    images: [],
    label: '',
  })
  const history = useHistory()

  const budgetContent = useMemo(() => {
    if (!quizQuestionData?.budgetContent) return []

    return quizQuestionData.budgetContent.map((bc) => ({ ...bc, label: mappingFocusLabel(bc.focus), value: bc.focus }))
  }, [quizQuestionData?.budgetContent])

  const gTagSetUserData = useCallback((param: any) => {
    const report = (window as { [key: string]: any })['gtag']
    if (report) {
      report('set', 'user_data', param)
    }
  }, [])

  const handleSubmit = async () => {
    try {
      const calculateStyleResult: API<ISubmitQuizQuestionResponse> = await submitQuizResponseAPI({
        sessionId: sessionID,
      })

      const leadValueConfig = calculateStyleResult.data.leadValueConfig
      const leadValuesByBudget = leadValueConfig?.leadValuesByBudget?.sort((a, b) => a.budget - b.budget) || []
      const leadValueToIndex = leadValuesByBudget.findIndex((l) => l.budget >= +budget.amount)
      const leadValueFrom = leadValueToIndex > 0 ? leadValuesByBudget[leadValueToIndex - 1] : undefined
      const leadValueTo = leadValueToIndex >= 0 ? leadValuesByBudget[leadValueToIndex] : undefined
      let leadValue: any
      if (leadValueFrom && leadValueTo) {
        const percent = (+budget.amount - leadValueFrom.budget) / (leadValueTo.budget - leadValueFrom.budget)
        if (leadValueFrom.value > leadValueTo.value) {
          leadValue = leadValueFrom.value - (leadValueFrom.value - leadValueTo.value) * percent
        } else {
          leadValue = (leadValueTo.value - leadValueFrom.value) * percent + leadValueFrom.value
        }
      }
      // If a style quiz session has a urlParam leadValueStrategy=regression (or there is no leadValueStrategy param), use the current approach.
      // If it has the leadValueStrategy=budget, use the older approach.
      // if (storeURLParameters?.leadValueStrategy !== 'budget') {
      //   leadValue = calculateStyleResult.data.leadValue
      // }

      const tracking = async () => {
        const promiseFunc = [
          gAdwords({
            value: leadValue,
            currency: 'USD',
            send_to: `${ENV.REACT_APP_GOOGLE_AW_CONVERSION}/${ENV.REACT_APP_GOOGLE_AW_CONVERSION_COMPLETE_QUIZ_EVENT_ID}`,
          }),
          gAdwords({
            value: leadValue,
            currency: 'USD',
            send_to: `${ENV.REACT_APP_GOOGLE_AW_CONVERSION_2}/${ENV.REACT_APP_GOOGLE_AW_CONVERSION_LEAD_ID}`,
          }),
          ga4Event(ACTION_APP.QUIZ_SUBMITTED),
          ga4Event(ACTION_APP.FULLY_QUALIFIED_LEAD),
          _segmentTracking(SegmentAction.SQ_QUIZ_SUBMITTED),
          _segmentTracking(SegmentAction.SQ_FULLY_QUALIFIED_LEAD),
          pintrk(),
          funcNoCallback(() => {
            gaEventApp(ACTION_APP.QUIZ_SUBMITTED)
            gaEventApp(ACTION_APP.FULLY_QUALIFIED_LEAD)
            fbEvent(EVENTS_FB_PIXEL.QUIZ2COMPLETE, {
              value: leadValue,
              currency: 'USD',
            })
            vwoEvent()
          }),
        ]
        await Promise.all(promiseFunc)
      }

      gTagSetUserData({
        email: data.weddingDate?.emailAddress || '',
        phone_number: data.weddingDate?.phoneNumber || '',
        address: {
          firstName: data.weddingDate?.firstName || '',
          lastName: data.weddingDate?.lastName || '',
        },
      })

      await tracking()

      if (calculateStyleResult.data.proposalExperiment?.includes(NP_EXPERIMENT.V1_OVERVIEW_PAGE)) {
        // push to NP overview page
        window.location.href = calculateStyleResult.data.proposalUrl
      } else {
        // push to moodboard
        const searchParams = getQueryParameters({ from: 'quiz-question' })
        history.push({ pathname: `/moodboard/${sessionID}`, search: searchParams.toString() })
      }
    } catch (error: any) {
      console.log('[handleSubmit]', error)
      alert(error?.data?.message ?? 'Submit failed')
    } finally {
      handleChangeAppData({ countLoadingTestimonial: -1 })
    }
  }

  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.FLOWERS_INTERSTITIAL)
  }
  const handleNext = async () => {
    handleChangeAppData({ countLoadingTestimonial: 1 })
    await setQuizData({ budget: +budget.amount, budgetFocus: budget.focus as BudgetFocus })
    segmentTracking(SegmentAction.SQ_CV_BUDGET_COMPLETED)
    handleSubmit()
  }

  const findClosestBudget = (newBudget: number, focus?: BudgetFocus | string) => {
    const listContent = budgetContent.filter((c) => (focus ? c.focus === focus : true))

    if (!listContent?.length) return
    if (listContent.length === 1) return listContent[0]
    let closest = listContent[0]
    for (let i = 1; i < listContent.length; i++) {
      const current = listContent[i]
      const currentDiff = Math.abs(current.amount - newBudget)
      const closestDiff = Math.abs(closest.amount - newBudget)
      if (currentDiff < closestDiff) {
        closest = current
      }
    }
    return closest
  }

  const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBudget = event.target.value === '' ? '' : Number(event.target.value)
    const closest = findClosestBudget(+newBudget, budget.focus)
    setBudget((pre) => ({
      ...pre,
      amount: newBudget,
      focus: closest?.value || budget.focus,
      label: closest?.label || budget.label,
      images: closest?.images || budget.images,
    }))
  }
  const increaseAmount = () => {
    setBudget((pre) => {
      const newBudget = Math.min(+pre.amount + AMOUNT_STEP, MAX_AMOUNT)
      const closest = findClosestBudget(+newBudget, budget.focus)
      return {
        ...pre,
        amount: newBudget,
        focus: closest?.value || pre.focus,
        label: closest?.label || pre.label,
        images: closest?.images || budget.images,
      }
    })
  }
  const decreaseAmount = () => {
    setBudget((pre) => {
      const newBudget = Math.max(+pre.amount - AMOUNT_STEP, MIN_AMOUNT)
      const closest = findClosestBudget(+newBudget, budget.focus)
      return {
        ...pre,
        amount: newBudget,
        focus: closest?.value || pre.focus,
        label: closest?.label || pre.label,
        images: closest?.images || budget.images,
      }
    })
  }

  const handleBlurAmount = () => {
    if (+budget.amount < MIN_AMOUNT) {
      const closest = findClosestBudget(MIN_AMOUNT, budget.focus)
      setBudget((pre) => ({
        ...pre,
        amount: MIN_AMOUNT,
        focus: closest?.value || pre.focus,
        label: closest?.label || pre.label,
        images: closest?.images || budget.images,
      }))
      return
    }
    if (+budget.amount > MAX_AMOUNT) {
      const closest = findClosestBudget(MAX_AMOUNT, budget.focus)
      setBudget((pre) => ({
        ...pre,
        amount: MAX_AMOUNT,
        focus: closest?.value || pre.focus,
        label: closest?.label || pre.label,
        images: closest?.images || budget.images,
      }))
    }
  }

  const handleChangeBudgetFocus = (option: IOption | null) => {
    if (option) {
      const closest = findClosestBudget(+budget.amount, option.value as BudgetFocus)
      setBudget({
        ...budget,
        focus: option?.value,
        label: option?.label,
        images: closest?.images || budget.images,
      })
    }
  }

  useEffect(() => {
    const closest = findClosestBudget(+budget.amount, budget.focus)
    if (closest)
      setBudget({
        amount: STARTING_AMOUNT,
        focus: closest.focus,
        images: closest.images,
        label: closest.label,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetContent])

  return {
    budget,
    budgetContent,
    BUDGET_FOCUS,
    handleBack,
    handleNext,
    decreaseAmount,
    increaseAmount,
    handleChangeAmount,
    handleBlurAmount,
    handleChangeBudgetFocus,
  }
}
