import React from 'react'
import requestAProposalImg from 'src/assets/images/stock.jpg'
import { useGetX4FromSession } from 'src/helpers/hooks/useGetX4FromSession'
import { QuizQuestionWrapper } from '../QuizQuestionWrapper'
import { useRequestAProposal } from './RequestAProposal.hook'

import './style.scss'

export const RequestAProposal = () => {
  const x4 = useGetX4FromSession()
  const { nextDisabled, handleNext, handleBack, recordResponse } = useRequestAProposal()

  return (
    <>
      <QuizQuestionWrapper
        titleStep="We’re available"
        backAction={{
          onAction: handleBack,
        }}
        nextAction={{
          onAction: handleNext,
          disabled: nextDisabled,
        }}
        step={x4 ? 3 : 2}
        total={x4 ? 5 : 4}
        className="request-a-proposal"
      >
        <div className="request-a-proposal-wrapper">
          <div className="left-part">
            <img src={requestAProposalImg} alt="request a proposal img" />
          </div>
          <div className="right-part">
            <div className="request-content">
              {recordResponse?.totalWeddings ? (
                <div className="weddings-done-wrapper">
                  <div className="weddings-done">
                    <span>
                      {`We've done ${recordResponse?.totalWeddings} wedding${
                        recordResponse?.totalWeddings === 1 ? '' : 's'
                      } at ${recordResponse.venueName}`}
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <h3 className="text">We’re available for your wedding!</h3>
              <div className="action-box-wrapper mt-30px pb-70px">
                <button disabled={nextDisabled} className="button white-space-wrap" onClick={handleNext}>
                  Add your budget
                </button>
              </div>
            </div>
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
