/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'

import { ModalStyle } from 'src/common/components/modal/ModalStyle'
import { Button, H3 } from 'src/common'
import { NumericFormat } from 'react-number-format'
// import flower from 'src/assets/images/flower.png'

import { useBudget, budgets } from './Budget.hook'
import { QuizQuestionWrapper } from '../QuizQuestionWrapper'

import { useGetX4FromSession } from 'src/helpers/hooks/useGetX4FromSession'
import flower from 'src/assets/images/modal-flower.png'

import './Budget.scss'
import { Helmet } from 'react-helmet'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props: any, ref: any) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      prefix="$"
      suffix={props.value >= 7500 ? '+' : ''}
    />
  )
})

export const Budget = () => {
  const x4 = useGetX4FromSession()
  const {
    indexNumberBudget,
    nextDisabled,
    isPopupBudget500,
    handleCheckNext,
    handleCloseWarningPopup,
    handleNext,
    handleBack,
    budgetSelected,
    setBudgetSelected,
  } = useBudget()

  const [valueIndex, setValueIndex] = useState(indexNumberBudget ?? -1)

  const option = budgets[valueIndex]

  const handleSliderChange = (sliderBudget: number) => {
    setBudgetSelected(sliderBudget)
    const index = budgets.findIndex((bd) => sliderBudget < +bd.value)
    setValueIndex(index < 0 ? 7 : index - 1)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBudgetSelected(event.target.value === '' ? '' : Number(event.target.value))
  }

  const handleBlur = () => {
    if (+budgetSelected < 1750) {
      setBudgetSelected(1750)
      setValueIndex(0)
    } else if (+budgetSelected > 7500) {
      setBudgetSelected(7500)
      setValueIndex(4)
    }
  }

  return (
    <>
      <Helmet>
        <script>
          {`
            function gtag_report_conversion(params){
              gtag('event', 'conversion', params);
              return false;
            }
          `}
        </script>
      </Helmet>
      <QuizQuestionWrapper
        titleStep="YOUR BUDGET"
        title="What’s your wedding flower budget?"
        subtitle="The Poppy order minimum is $1750."
        nextAction={{
          disabled: nextDisabled,
          onAction: handleCheckNext,
        }}
        backAction={{
          onAction: handleBack,
        }}
        step={x4 ? 4 : 3}
        total={x4 ? 5 : 4}
      >
        <div className="quiz-budget">
          {/* <div className="quiz-question-header">What is your wedding flower budget?</div> */}
          <div className="guests-wrapper text-transform-upper range-slider-option">
            <div className="img-wrapper bg-img-budget">
              {option && option.image && (
                <div className="slider-image">
                  <img src={option.image} alt={option.label}></img>
                </div>
              )}
            </div>
            <div className="quiz-budget-option-wrapper">
              {/* {budgets.map((item, index) => (
                <Radio
                  key={item.value}
                  id={item.label}
                  value={item.value}
                  name="budgets"
                  classNames={{ container: 'mr-10px', label: 'basic' }}
                  checked={indexNumberBudget === index}
                  onChange={() => handleChangeValue(index)}
                >
                  {item.label}
                </Radio>
              ))} */}
              <div className="quiz-budget-slider">
                <p className="mb-16px">Poppy wedding flowers start at $1,750.</p>
                <NumberFormatCustom
                  thousandSeparator={true}
                  value={budgetSelected}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  name="budgetNumber"
                  className="budget-input"
                />
                <div className="budget-slider mt-22px">
                  <Slider value={+budgetSelected} step={250} min={1750} max={7500} onChange={handleSliderChange} />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mt-20px m-center">
            <button disabled={nextDisabled} className="button button-primary mt-14px" onClick={handleCheckNext}>
              See my style
            </button>
          </div>
          <div className="mt-20px m-center">
            <span className="submit-note">
              By signing up via text, you agree to receive recurring automated marketing text messages from Poppy
              Flowers at the cell number used when signing up. Consent is not a condition of any purchase. Msg & data
              rates may apply. Unsubscribe at anytime. See Terms & Privacy.
            </span>
          </div> */}
          <div className="action-box-wrapper mt-34px mb-30px pb-70px">
            <button disabled={nextDisabled} className="button white-space-wrap" onClick={handleCheckNext}>
              Add your Color palette
            </button>
          </div>
        </div>
      </QuizQuestionWrapper>
      <ModalStyle className="budget-warning" isOpen={isPopupBudget500} handleClose={handleCloseWarningPopup}>
        <div className="warning-wrapper">
          <div className="pin-logo">
            <H3>Heads up!</H3>
          </div>
          <div className="warning-content">
            <div className="text-content mb-20rem">
              Our custom wedding flowers start at $1,000. Continue to finish the quiz and work with your proposal
              designer to pick your perfect custom wedding flowers.
            </div>
            <div className="">
              <Button className="button button-primary mlr-10rem mb-10rem" onClick={handleCloseWarningPopup}>
                go back
              </Button>
              <Button className="button button-secondary mlr-10rem mb-10rem" onClick={handleNext}>
                continue
              </Button>
              <img src={flower} className="modal-flower" alt="flower" />
            </div>
          </div>
        </div>
      </ModalStyle>
    </>
  )
}
