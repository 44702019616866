/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AppContext, useAppContext } from 'src/helpers/app'
import { IQuizData, QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../contextAPI'
import { budgets, BUDGET_500_INDEX } from './Budget/Budget.hook'
import { EVENTS_FB_PIXEL, fbEvent, PATH_API } from 'src/helpers'
import { API, ColorPalette } from 'src/types'
import { ACTION_APP, ga4EventApp, gaEventApp } from 'src/helpers/googleAnalyticsService'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'
import { postHttp } from 'src/api'
import { ENV } from 'src/config/env'
import { vwoEvent } from 'src/helpers/vwo'

interface GuineaPigConfigValue {
  enabled: boolean
  budget: {
    enabled: boolean
    min: number
    max: number
  }
  colorPalette: {
    enabled: boolean
    colorPaletteIds: number[]
  }
  planningStage: {
    enabled: boolean
    planningStages: string[]
  }
}

interface GuineaPigConfig {
  value: GuineaPigConfigValue
}

interface ISubmitQuizQuestionResponse {
  redirectLink: string
  integratedFireworkExperience: boolean
  premerchandizedKits: boolean
  isActivePremerchandizedKits: boolean
  isActive1000Customer: boolean
  proposalUrl: string
  shouldReportAtiumConversion: boolean
  leadValueConfig: { leadValuesByBudget: { budget: number; value: number }[] }
  leadValue: number
  guineaPigConfig: GuineaPigConfig
}

interface ISubmitQuizQuestionPayload {
  sessionId: string
  firstName?: string
  lastName?: string
  emailAddress?: string
  attributionSource?: string
}

const submitQuizResponseAPI = (submitData: ISubmitQuizQuestionPayload) => {
  return postHttp(PATH_API.postSubmit, submitData)
}

const MAX_TIME_TRACKING = 1500

const getShowGuineaPig = (
  data: IQuizData,
  paletteSelected: ColorPalette | undefined,
  config: GuineaPigConfigValue | undefined
) => {
  console.log('Determining whether to show guinea pig')
  console.log(JSON.stringify(data))
  console.log(JSON.stringify(paletteSelected))
  console.log(JSON.stringify(config))
  if (!config || !config.enabled) return false

  const budgetSelected = data.budget ?? +budgets[2].value
  return (
    (!config.budget.enabled || (budgetSelected >= config.budget.min && budgetSelected <= config.budget.max)) &&
    (!config.colorPalette.enabled || config.colorPalette.colorPaletteIds.includes(paletteSelected?.id || -1)) &&
    (!config.planningStage.enabled || config.planningStage.planningStages.includes(data.planningStage?.stage || ''))
  )
}

export const useColorsFlowers = () => {
  const history = useHistory()
  const { data, setQuizScreen, setQuizData, quizQuestionData, setRedirectUrl } = useContext(QuizQuestionContext)
  const [paletteSelected, setPaletteSelected] = useState<ColorPalette | undefined>(data.colorPalette)
  const [budgetSelected] = useState(data.budget ?? +budgets[2].value)
  const { appData } = useAppContext()
  const { sessionID, storeURLParameters, handleChangeAppData } = useContext(AppContext)

  const gTagSetUserData = useCallback((param: any) => {
    const report = (window as { [key: string]: any })['gtag']
    if (report) {
      report('set', 'user_data', param)
    }
  }, [])

  const handleSubmit = async () => {
    try {
      const calculateStyleResult: API<ISubmitQuizQuestionResponse> = await submitQuizResponseAPI({
        sessionId: sessionID,
      })

      //update here
      const showAfterQuiz = getShowGuineaPig(data, paletteSelected, calculateStyleResult.data.guineaPigConfig?.value)

      const leadValueConfig = calculateStyleResult.data.leadValueConfig
      const leadValuesByBudget = leadValueConfig?.leadValuesByBudget?.sort((a, b) => a.budget - b.budget) || []
      const leadValueToIndex = leadValuesByBudget.findIndex((l) => l.budget >= budgetSelected)
      const leadValueFrom = leadValueToIndex > 0 ? leadValuesByBudget[leadValueToIndex - 1] : undefined
      const leadValueTo = leadValueToIndex >= 0 ? leadValuesByBudget[leadValueToIndex] : undefined
      let leadValue: any
      if (leadValueFrom && leadValueTo) {
        const percent = (budgetSelected - leadValueFrom.budget) / (leadValueTo.budget - leadValueFrom.budget)
        if (leadValueFrom.value > leadValueTo.value) {
          leadValue = leadValueFrom.value - (leadValueFrom.value - leadValueTo.value) * percent
        } else {
          leadValue = (leadValueTo.value - leadValueFrom.value) * percent + leadValueFrom.value
        }
      }
      // If a style quiz session has a urlParam leadValueStrategy=regression (or there is no leadValueStrategy param), use the current approach.
      // If it has the leadValueStrategy=budget, use the older approach.
      // if (storeURLParameters?.leadValueStrategy !== 'budget') {
      //   leadValue = calculateStyleResult.data.leadValue
      // }

      const tracking = async () => {
        const gAdwords = (param: any) =>
          new Promise((rs) => {
            const report = (window as { [key: string]: any })['gtag_report_conversion']
            // We report adwords conversions by inserting a function into the <head> of the page.
            if (report) {
              report({ ...param, event_callback: rs })
              setTimeout(rs, MAX_TIME_TRACKING)
            } else {
              rs(true)
            }
          })
        const ga4Event = (action: ACTION_APP, params: any = {}) =>
          new Promise((rs) => {
            ga4EventApp(action, { ...params, event_callback: rs })
            setTimeout(rs, MAX_TIME_TRACKING)
          })
        const _segmentTracking = (action: string) =>
          new Promise((rs) => {
            segmentTracking(action, {}, {}, rs)
            setTimeout(rs, MAX_TIME_TRACKING)
          })
        const pintrk = () =>
          new Promise((rs) => {
            const windowGlobal = window as any
            if (windowGlobal.pintrk) {
              windowGlobal.pintrk('track', 'lead', { value: leadValue }, function (didInit: boolean, error: any) {
                if (!didInit) {
                  console.log(error)
                }
                rs(true)
              })
            }
            setTimeout(rs, MAX_TIME_TRACKING)
          })
        // Cheat: timeout for lib don't support callback
        const funcNoCallback = () =>
          new Promise((rs) => {
            gaEventApp(ACTION_APP.QUIZ_SUBMITTED)
            gaEventApp(ACTION_APP.FULLY_QUALIFIED_LEAD)
            fbEvent(EVENTS_FB_PIXEL.QUIZ2COMPLETE, {
              value: leadValue,
              currency: 'USD',
            })
            vwoEvent()
            setTimeout(rs, MAX_TIME_TRACKING)
          })
        const promiseFunc = [
          gAdwords({
            value: leadValue,
            currency: 'USD',
            send_to: `${ENV.REACT_APP_GOOGLE_AW_CONVERSION}/${ENV.REACT_APP_GOOGLE_AW_CONVERSION_COMPLETE_QUIZ_EVENT_ID}`,
          }),
          gAdwords({
            value: leadValue,
            currency: 'USD',
            send_to: `${ENV.REACT_APP_GOOGLE_AW_CONVERSION_2}/${ENV.REACT_APP_GOOGLE_AW_CONVERSION_LEAD_ID}`,
          }),
          ga4Event(ACTION_APP.QUIZ_SUBMITTED),
          ga4Event(ACTION_APP.FULLY_QUALIFIED_LEAD),
          _segmentTracking(SegmentAction.SQ_QUIZ_SUBMITTED),
          _segmentTracking(SegmentAction.SQ_FULLY_QUALIFIED_LEAD),
          pintrk(),
          funcNoCallback(),
        ]
        await Promise.all(promiseFunc)
      }

      // premerchandizedKits
      // if (
      //   ENV.PREMERCHANDIZED_KITS &&
      //   calculateStyleResult.data.premerchandizedKits &&
      //   calculateStyleResult.data.isActivePremerchandizedKits
      // ) {
      //   const params = new URL(calculateStyleResult.data.redirectLink)
      //   history.push(`${PATH_URL.$500_CUSTOMER}${params.search}`, { from: 'quiz-question' })
      // } else
      if (calculateStyleResult.data.proposalUrl) {
        // integratedFireworkExperience
        // } else if (calculateStyleResult?.data?.integratedFireworkExperience || ENV.INTEGRATED_FIREWORK_EXPERIENCE) {
        try {
          gTagSetUserData({
            email: data.weddingDate?.emailAddress || '',
            phone_number: data.weddingDate?.phoneNumber || '',
            address: {
              firstName: data.weddingDate?.firstName || '',
              lastName: data.weddingDate?.lastName || '',
            },
          })
          await tracking()
          console.log('tracking success')
        } catch (error) {}
        if (showAfterQuiz) {
          setRedirectUrl(calculateStyleResult.data.proposalUrl)
          setQuizScreen(SCREEN_QUIZ_QUESTION.AFTER_QUIT_SUBMIT)
        } else {
          window.location.href = calculateStyleResult.data.proposalUrl
        }
        // const params = new URL(calculateStyleResult.data.redirectLink)
        // history.push(`${PATH_URL.YOUR_POPPY_PROPOSAL}${params.search}`, { from: 'quiz-question' })
      } else {
        if (showAfterQuiz) {
          setRedirectUrl(calculateStyleResult?.data?.redirectLink || `https://www.poppyflowers.com/`)
          setQuizScreen(SCREEN_QUIZ_QUESTION.AFTER_QUIT_SUBMIT)
        } else {
          window.location.href = calculateStyleResult?.data?.redirectLink || `https://www.poppyflowers.com/`
        }
      }
      // Cheat: Single loading state during quiz submission
      // handleChangeAppData({ countLoadingTestimonial: -1 })
      if (showAfterQuiz) {
        handleChangeAppData({ countLoadingTestimonial: -1 })
      }
    } catch (error: any) {
      console.log('[handleSubmit]', error)
      handleChangeAppData({ countLoadingTestimonial: -1 })
      alert(error?.data?.message ?? 'Submit failed')
    }
  }

  const handleNext = async () => {
    handleChangeAppData({ countLoadingTestimonial: 1 })
    if (appData.isMicroWedding) {
      await setQuizData({ colorPalette: paletteSelected, budget: +budgets[BUDGET_500_INDEX].value })
    } else {
      await setQuizData({ colorPalette: paletteSelected })
    }
    handleSubmit()
  }

  const handleBack = () => {
    if (appData.isMicroWedding) {
      history.goBack()
      setQuizScreen(SCREEN_QUIZ_QUESTION.BUDGET)
    } else {
      history.goBack()
      setQuizScreen(SCREEN_QUIZ_QUESTION.BUDGET)
    }
  }

  const nextDisabled = useMemo(() => !paletteSelected, [paletteSelected])

  useEffect(() => {
    if (!paletteSelected && quizQuestionData?.colorPalettes?.length) {
      setPaletteSelected(quizQuestionData.colorPalettes[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizQuestionData?.colorPalettes?.length])

  return {
    colorPalettes: quizQuestionData?.colorPalettes || [],
    nextDisabled,
    handleBack,
    handleNext,
    paletteSelected,
    setPaletteSelected,
  }
}
