import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { SegmentCategory, SegmentPageName, segmentPageTracking } from '../segment'
import { PATH_URL } from '../path'

export function useSegmentPage() {
  const location = useLocation()
  useEffect(() => {
    const locationListener = () => {
      const url = window.location.href
      switch (location.pathname) {
        case PATH_URL.QUIZ_QUESTION:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.QUIZ, { url })
          break
        case PATH_URL.DETAILS:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.DETAILS, { url })
          break
        case PATH_URL.PERSONAL_DETAIL:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.PERSONAL_DETAIL, {
            url,
          })
          break
        case PATH_URL.WEDDING_DETAIL:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.WEDDING_DETAIL, { url })
          break
        case PATH_URL.AVAILABILITY_PAGE:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.AVAILABILITY_PAGE, {
            url,
          })
          break
        case PATH_URL.BUDGET_PAGE:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.BUDGET_PAGE, { url })
          break
        case PATH_URL.COLOR_PALETTE_PAGE:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.COLOR_PALETTE_PAGE, {
            url,
          })
          break
        case PATH_URL.EXISTING_PROPOSAL:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.EXISTING_PROPOSAL, {
            url,
          })
          break
        case PATH_URL.QUIZ_END:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.QUIZ_END, { url })
          break
        case PATH_URL.STYLE_INFO:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.STYLE_INFO, { url })
          break
        case PATH_URL.YOUR_POPPY_PROPOSAL:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.YOUR_POPPY_PROPOSAL, {
            url,
          })
          break
        case PATH_URL.CALL_SCHEDULED:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.CALL_SCHEDULED, { url })
          break
        case PATH_URL.$500_CUSTOMER:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.$500_CUSTOMER, { url })
          break
        case PATH_URL.SCHEDULE_YOUR_CONSULTATION:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.SCHEDULE_YOUR_CONSULTATION, {
            url,
          })
          break
        case PATH_URL.MOODBOARD:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.MOODBOARD, { url })
          break
        case PATH_URL.POST_MOODBOARD:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.POST_MOODBOARD, { url })
          break
        case PATH_URL.SCHEDULE_A_CALL:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.SCHEDULE_A_CALL, {
            url,
          })
          break
        default:
          segmentPageTracking(SegmentCategory.STYLE_QUIZ, SegmentPageName.UNKNOWN, { url })
          break
      }
    }

    locationListener()
  }, [location])
}
