/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { IRangeSliderOptionItem } from 'src/common/components/slider'

import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'

import slider500Web from 'src/assets/images/Slider_500_Web.png'
import slider1500Web from 'src/assets/images/Slider_1500_Web.png'
import slider2500Web from 'src/assets/images/Slider_2500_Web.png'
import slider3500Web from 'src/assets/images/Slider_3500_Web.png'
import slider4500Web from 'src/assets/images/Slider_4500_Web.png'
import slider500Mobile from 'src/assets/images/Slider_500_Mobile.png'
import slider1500Mobile from 'src/assets/images/Slider_1500_Mobile.png'
import slider2500Mobile from 'src/assets/images/Slider_2500_Mobile.png'
import slider3500Mobile from 'src/assets/images/Slider_3500_Mobile.png'
import slider4500Mobile from 'src/assets/images/Slider_4500_Mobile.png'
import { PATH_URL, getQueryParameters } from 'src/helpers'
// import { ENV } from 'src/config/env'
// import { useHistory } from 'react-router-dom'

export const BUDGET_500_INDEX = 0

export const budgets: IRangeSliderOptionItem[] = [
  {
    label: '$1,750',
    value: '1750',
    image: slider500Web,
  },
  {
    label: '$2,000',
    value: '2000',
    image: slider500Web,
  },
  {
    label: '$3,000',
    value: '3000',
    image: slider1500Web,
  },
  {
    label: '$4,000',
    value: '4000',
    image: slider2500Web,
  },
  {
    label: '$5,250',
    value: '5250',
    image: slider3500Web,
  },
  {
    label: '$6,500',
    value: '6500',
    image: slider4500Web,
  },
  {
    label: '$7,500',
    value: '7500',
    image: slider4500Web,
  },
  {
    label: '$8,500',
    value: '8500',
    image: slider4500Web,
  },
  {
    label: '$9,000+',
    value: '9000',
    image: slider4500Web,
  },
]

export const useBudget = () => {
  const history = useHistory()
  const { data, setQuizData, setQuizScreen } = useContext(QuizQuestionContext)
  const [budgetSelected, setBudgetSelected] = useState<number | string>(data.budget ?? +budgets[2].value)
  const [isPopupBudget500, setIsPopupBudget500] = useState(false)

  const handleNext = async () => {
    await setQuizData({ budget: +budgetSelected })
    setQuizScreen(SCREEN_QUIZ_QUESTION.COLORS_FLOWERS)
    const searchParams = getQueryParameters()
    history.push({ pathname: PATH_URL.COLOR_PALETTE_PAGE, search: searchParams.toString() })
  }

  const handleBack = () => {
    history.goBack()
    // setQuizScreen(SCREEN_QUIZ_QUESTION.WEDDING_DATE)
    setQuizScreen(SCREEN_QUIZ_QUESTION.YOU_BOOKED)
  }

  const handleCheckNext = () => {
    if (+budgetSelected <= 500) {
      setIsPopupBudget500(true)
    } else {
      handleNext()
    }
  }

  const handleCloseWarningPopup = () => {
    setIsPopupBudget500(false)
  }

  const nextDisabled = useMemo(() => {
    return !budgetSelected
  }, [budgetSelected])

  const indexNumberBudget = budgets.findIndex((item) => +item.value === budgetSelected)

  return {
    nextDisabled,
    budgetSelected,
    indexNumberBudget,
    isPopupBudget500,
    handleCheckNext,
    handleCloseWarningPopup,
    handleBack,
    handleNext,
    setBudgetSelected,
  }
}
