import { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useGetX4FromSession } from 'src/helpers/hooks/useGetX4FromSession'
import { PATH_URL, getQueryParameters } from 'src/helpers'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'

export const useRequestAProposal = () => {
  const history = useHistory()
  const x4 = useGetX4FromSession()
  const { data, setQuizScreen, recordResponse } = useContext(QuizQuestionContext)

  const handleNext = () => {
    const searchParams = getQueryParameters()
    history.push({ pathname: PATH_URL.BUDGET_PAGE, search: searchParams.toString() })
    setQuizScreen(SCREEN_QUIZ_QUESTION.BUDGET)
  }

  const handleBack = () => {
    history.goBack()
    if (x4) {
      setQuizScreen(SCREEN_QUIZ_QUESTION.WEDDING_DETAILS)
    } else {
      setQuizScreen(SCREEN_QUIZ_QUESTION.WEDDING_DATE)
    }
  }

  const nextDisabled = useMemo(() => {
    return false
  }, [])

  return {
    data,
    nextDisabled,
    handleBack,
    handleNext,
    recordResponse,
  }
}
