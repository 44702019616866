import { createContext, useState, Dispatch, SetStateAction, useContext } from 'react'
import { IImageFireWork } from 'src/config/fireworkConfig'
import { CalculateResponse, ICustomer, ILocationQuiz, IObjectDynamicPrototype } from 'src/types'
import { IProductOptions } from 'src/types/product'
import { IScheduledEventInfo } from 'src/types/scheduledEvent'
import { IQuizSessionsResponse, QuizExperience } from '../../types/api'

export type PrimaryCTAType = 'ENGAGEMENT' | 'BOOK_NOW' | 'SEE_PROPOSAL'
export type MoodboardSourceType = 'COLOR_PALETTE'

export interface ICustomerFullInfo {
  integratedFireworkExperience: boolean
  templateConfig: IImageFireWork
  customerData: {
    name: string
    email: string
    lastName: string
    phoneNumber: number
    locations: ILocationQuiz[]
    additionalBouquetsNeeded: number
    additionalBoutsNeeded: number
    styleText: string
    guestCount: number
    colors: string[]
    budgetRange: number[]
    budget: number
    experiments: { paywall: boolean }
    eventDate: string | Date
    marketName: string
    countMarketBook: number
  }
  templateId: number
  lookbookExampleUrl: string
  photographerAttribution: string
  productOptions: IProductOptions
  hotlineEnabled: boolean
  ctaConfigs: string[]
  proposalUrl: string
  bookNowUrl: string
  primaryCTAType: PrimaryCTAType
  calendlyLink?: string
  moodboardImages: {
    id: number
    imageUrl: string
    position: number
    sourceType: MoodboardSourceType
  }[]
  colorPalette: {
    id: number
    name: string
    colors: unknown
    colorList: string[]
    images: unknown
    customerName: string
    location: string
    moodboardImages: unknown
    packageImages: {
      PACKAGE_SIMPLE: { mainImageUrl: string; additionalImageUrls: string[] }
      PACKAGE_LUSH: { mainImageUrl: string; additionalImageUrls: string[] }
      PACKAGE_PREMIUM: { mainImageUrl: string; additionalImageUrls: string[] }
    }
  }
}

interface IAppData {
  isLoading?: boolean
  countLoading?: number
  countLoadingTestimonial?: number
  isConversation?: boolean
  isQuizSubmitted?: boolean
  customer?: ICustomer
  styleInfo?: CalculateResponse
  customerFullInfo?: ICustomerFullInfo
  scheduledEventInfo?: IScheduledEventInfo
  isMicroWedding?: boolean
  isCustomer1000?: boolean
  scheduleModal?: boolean
}

export interface AppContextData {
  sessionID: string
  storeURLParameters: IObjectDynamicPrototype | null
  appData: IAppData
  quizSession: IQuizSessionsResponse | null
  quizExperience: QuizExperience
  experiment: string[]
  handleChangeAppData: (data: IAppData) => void
  setSessionID: Dispatch<SetStateAction<string>>
  setStoreURLParameters: Dispatch<SetStateAction<IObjectDynamicPrototype | null>>
  setQuizSession: Dispatch<SetStateAction<IQuizSessionsResponse | null>>
  setQuizExperience: Dispatch<SetStateAction<QuizExperience>>
  setExperiment: Dispatch<SetStateAction<string[]>>
}

export const appContextDefaultValue: AppContextData = {
  sessionID: '',
  quizSession: null,
  storeURLParameters: null,
  appData: {
    isLoading: true,
    countLoading: 0,
    isQuizSubmitted: false,
    scheduleModal: false,
  },
  quizExperience: 'LEGACY',
  experiment: [],
  handleChangeAppData: () => null,
  setSessionID: () => null,
  setStoreURLParameters: () => null,
  setQuizSession: () => null,
  setQuizExperience: () => null,
  setExperiment: () => null,
}

export const AppContext = createContext<AppContextData>({ ...appContextDefaultValue })

export const useAppContextValue = (): AppContextData => {
  const [sessionID, setSessionID] = useState<string>(appContextDefaultValue.sessionID)
  const [quizSession, setQuizSession] = useState<IQuizSessionsResponse | null>(appContextDefaultValue.quizSession)
  const [appData, setAppData] = useState<IAppData>(appContextDefaultValue.appData)
  const [quizExperience, setQuizExperience] = useState<QuizExperience>(appContextDefaultValue.quizExperience)
  const [experiment, setExperiment] = useState<string[]>(appContextDefaultValue.experiment)
  const [storeURLParameters, setStoreURLParameters] = useState<IObjectDynamicPrototype | null>(
    appContextDefaultValue.storeURLParameters
  )

  const handleChangeAppData = (value: IAppData) => {
    if (value.countLoading) {
      const { countLoading, ...otherValue } = value
      setAppData((data) => ({ ...data, ...otherValue, countLoading: (data.countLoading || 0) + countLoading }))
    } else if (value.countLoadingTestimonial) {
      const { countLoadingTestimonial, ...otherValue } = value
      setAppData((data) => ({
        ...data,
        ...otherValue,
        countLoadingTestimonial: (data.countLoadingTestimonial || 0) + countLoadingTestimonial,
      }))
    } else {
      setAppData((data) => ({ ...data, ...value }))
    }
  }

  return {
    sessionID,
    storeURLParameters,
    appData,
    handleChangeAppData,
    setSessionID,
    setStoreURLParameters,
    setQuizSession,
    quizSession,
    quizExperience,
    setQuizExperience,
    experiment,
    setExperiment,
  }
}

export function useAppContext() {
  const appContext = useContext(AppContext)
  if (!appContext) {
    throw new Error('useAppContext must be used within the AppContext.Provider')
  }

  return appContext
}
