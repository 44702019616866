import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, H2, ModalBase, PinDesktopIcon, PinMobileIcon, XIcon } from 'src/common'
import { getQueryParameters, isMobile, redirect, REDIRECT_PATH } from 'src/helpers'
// import logo from 'src/assets/images/logo.png'

import { QuizQuestionContext, UNSERVICEABLE_REASON } from '../../contextAPI'

interface IDesktopUnserviceableProps {
  handleClose: () => void
  handleClickHome: () => void
  content: string
}

function UnserviceableDesktop(props: IDesktopUnserviceableProps) {
  return (
    <ModalBase
      isOpen={true}
      hiddenClose={true}
      handleClose={props.handleClose}
      classModal="modal-base desktop unserviceable-modal"
      closeElm={
        <p className="modal-close" onClick={props.handleClose}>
          <XIcon />
        </p>
      }
    >
      <div className="unserviceable-wrapper">
        <div className="pin-desktop">
          <PinDesktopIcon />
        </div>
        <H2 className="title mt-0px-auto">Uh oh!</H2>
        <div className="fz-15rem mb-20rem text-content">{props.content}</div>
        <div className="">
          <Button className="button button-primary mlr-10rem mb-10rem btn-take-home" onClick={props.handleClickHome}>
            Take me home
          </Button>
          <Button className="button button-secondary mlr-10rem mb-10rem btn-try-again" onClick={props.handleClose}>
            Try Again
          </Button>
        </div>
      </div>
    </ModalBase>
  )
}

function UnserviceableMobile(props: IDesktopUnserviceableProps) {
  return (
    <ModalBase
      isOpen={true}
      hiddenClose={true}
      handleClose={props.handleClose}
      classModal="modal-base mobile unserviceable-modal"
      closeElm={
        <p className="modal-close" onClick={props.handleClose}>
          <XIcon />
        </p>
      }
    >
      <div className="unserviceable-wrapper">
        <H2 className="title">Uh oh!</H2>
        <div className="pin-mobile">
          <PinMobileIcon />
        </div>
        <div className="fz-15rem mb-20rem plr-10rem text-content">{props.content}</div>
        <div className="button-wrapper">
          <Button className="button button-secondary mlr-10rem mb-10rem btn-try-again" onClick={props.handleClose}>
            Try Again
          </Button>
          <Button className="button button-primary mlr-10rem mb-10rem btn-take-home" onClick={props.handleClickHome}>
            Take me home
          </Button>
        </div>
      </div>
    </ModalBase>
  )
}

export const Unserviceable = () => {
  const history = useHistory()
  const { recordResponse, setRecordResponse } = useContext(QuizQuestionContext)

  const handleClose = () => {
    setRecordResponse({ ...recordResponse, serviceable: undefined })
  }

  const handleClickHome = () => {
    const searchParams = getQueryParameters()
    redirect(`${REDIRECT_PATH.HOME}?${searchParams.toString()}`)
  }

  useEffect(() => {
    const searchParams = getQueryParameters()
    const path = `${history.location.pathname}#unavailable?${searchParams.toString()}`
    history.replace(path)

    return () => {
      const path = history.location.pathname?.replace('#unavailable', '').concat(`?${searchParams.toString()}`)
      history.replace(path)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!recordResponse) {
    return <></>
  }

  let content = ''
  if (recordResponse.unserviceableReason === UNSERVICEABLE_REASON.DATE) {
    content = 'We do not have the required time it takes to bring your vision to life.'
  } else if (recordResponse.unserviceableReason === UNSERVICEABLE_REASON.LOCATION && !recordResponse.validLocation) {
    content =
      "Invalid venue location found. Try again with the name of your venue or the city and state where you're getting married"
  } else {
    content = 'We do not have a designer in your area in order to bring your vision to life.'
  }

  return isMobile ? (
    <UnserviceableMobile handleClose={handleClose} handleClickHome={handleClickHome} content={content} />
  ) : (
    <UnserviceableDesktop handleClose={handleClose} handleClickHome={handleClickHome} content={content} />
  )
}
