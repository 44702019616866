/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SESSION_NAME_LOCAL, useAppContext } from 'src/helpers/app'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from './contextAPI'
import { PATH_URL, getQueryParameters, splitFullName } from 'src/helpers'
import { EVENT_LOCATION_TYPE, IStringDynamicPrototype } from 'src/types'
import { segmentIdentify } from 'src/helpers/segment'
import { useGetX4FromUrl } from 'src/helpers/hooks/useGetX4FromUrl'
import { QuizExperience } from '../shared'

type ExternalQuizData = {
  yourName: string
  partnerName: string
  email: string
  phone: string
  eventDate: string
  planningStage: string
  location: string
  referralSource?: string
}

export const useExternalQuiz = () => {
  const history = useHistory()
  const x4 = useGetX4FromUrl()
  const { sessionID, storeURLParameters, setStoreURLParameters, quizExperience, handleChangeAppData } = useAppContext()
  const { setQuizData, setQuizScreen, setRecordResponse } = useContext(QuizQuestionContext)
  const quizDataString = atob((storeURLParameters?.quizData as string) || '')

  const gTagSetUserData = useCallback((param: any) => {
    const report = (window as { [key: string]: any })['gtag']
    if (report) {
      report('set', 'user_data', param)
    }
  }, [])

  const recordQuiz = useCallback(async (quizData: ExternalQuizData) => {
    // record quiz
    try {
      handleChangeAppData({ countLoading: 1 })
      const [firstName, lastName] = splitFullName(quizData.yourName)
      const [partnerFirstName, partnerLastName] = splitFullName(quizData.partnerName)
      const recordResponse = await setQuizData({
        weddingDate: {
          fullName: quizData.yourName,
          partnerName: quizData.partnerName,
          weddingDate: quizData.eventDate,
          firstName,
          lastName,
          partnerFirstName,
          partnerLastName,
          emailAddress: quizData.email,
          phoneNumber: quizData.phone,
        },
        location: [
          {
            type: EVENT_LOCATION_TYPE.CEREMONY,
            venue: '',
            state: '',
            streetAddress: '',
            city: '',
            zipCode: '',
            place: quizData.location,
          },
        ],
        planningStage: { stage: quizData.planningStage, vendors: {}, intent: '' },
        referralSource: quizData.referralSource,
        experiment: x4 ? [QuizExperience.X4_QUIZ_DETAILS] : [],
      })

      segmentIdentify(sessionID, {
        name: quizData.yourName,
        email: quizData.email,
        location: quizData.location,
        serviceable: recordResponse?.data.serviceable,
        externalQuiz: true,
      })
      gTagSetUserData({
        email: quizData.email,
        phone_number: quizData.phone,
        address: {
          firstName: firstName,
          lastName: lastName,
        },
      })
      handleChangeAppData({ countLoading: -1 })
      if (recordResponse?.data) {
        setRecordResponse(recordResponse.data)
        const searchParams = getQueryParameters()

        if (recordResponse.data.validateError?.email) {
          toast.error('Email do not exist!')
          return
        }
        if (recordResponse?.data?.proposal?.hadProposal) {
          searchParams?.delete('quizData')
          searchParams?.delete('x4')
          let searchParamsObject: IStringDynamicPrototype = {}
          searchParamsObject = Object.fromEntries(searchParams)
          setStoreURLParameters(searchParamsObject)
          history.push({ pathname: PATH_URL.EXISTING_PROPOSAL, search: searchParams.toString() })
          setQuizScreen(SCREEN_QUIZ_QUESTION.MET_BEFORE)
          return
        }
        if (recordResponse.data.serviceable) {
          if (quizExperience === 'CANNY_VALLEY') {
            setQuizScreen(SCREEN_QUIZ_QUESTION.CHOOSE_PALETTE)
          } else {
            searchParams?.delete('quizData')
            searchParams?.delete('x4')
            let searchParamsObject: IStringDynamicPrototype = {}
            searchParamsObject = Object.fromEntries(searchParams)
            setStoreURLParameters(searchParamsObject)
            history.push({ pathname: PATH_URL.AVAILABILITY_PAGE, search: searchParams?.toString() })
            setQuizScreen(SCREEN_QUIZ_QUESTION.YOU_BOOKED)
          }
        } else {
          // remove session_id after user exit => allow user can take the other quiz with new email
          localStorage.setItem(SESSION_NAME_LOCAL, '')
        }
      }
    } catch (err) {
      handleChangeAppData({ countLoading: -1 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useMemo(() => {
    if (!quizDataString) return null

    console.log('external quiz data: ', quizDataString)
    try {
      const tempQuizData: ExternalQuizData = JSON.parse(quizDataString)
      const isInvalid =
        !tempQuizData.yourName ||
        !tempQuizData.partnerName ||
        !tempQuizData.email ||
        !tempQuizData.phone ||
        !tempQuizData.eventDate ||
        !tempQuizData.location ||
        !tempQuizData.planningStage

      if (isInvalid) {
        toast.error(
          'Missing data! Please include all mandatory fields: Your name, Partner name, Email, Phone, Event Date, Location, and PlanningStage.'
        )
        return null
      }
      recordQuiz(tempQuizData)
    } catch (err) {
      toast.error('Invalid data.')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizDataString])
}
