import { createContext } from 'react'
import {
  API,
  BudgetContent,
  BudgetFocus,
  ColorPalette,
  ColorPreference,
  CustomerStemPreference,
  IBooleanDynamicPrototype,
  ILocationQuiz,
  IOption,
  Inspiration,
  QuizExperience,
  Stem,
} from 'src/types'

export enum SCREEN_QUIZ_QUESTION {
  WEDDING_DATE = 'WEDDING_DATE',
  RITUAL = 'RITUAL',
  BUDGET = 'BUDGET',
  BOUQUET_SHAPE = 'BOUQUET_SHAPE',
  FLORAL_FOCUS = 'FLORAL_FOCUS',
  YOU_BOOKED = '1',
  LOCATION = '2',
  GUESTS_AND_TABLE_EXPECT = '3',
  FLOWERS_WEDDING = '4',
  SEE_ITEM_PRICE = '5',
  COLORS_FLOWERS = '6',
  AFTER_QUIT_SUBMIT = '7',
  ANYTHING = '8',
  DONE = '9',
  MET_BEFORE = 'MET_BEFORE',
  END_QUIZ = 'END_QUIZ',

  // CANNY VALLEY
  WELCOME = 'WELCOME',
  COUPLE_INFO = 'COUPLE_INFO',
  CHOOSE_PALETTE = 'CHOOSE_PALETTE',
  CUSTOM_PALETTE = 'CUSTOM_PALETTE',
  INSPIRATION = 'INSPIRATION',
  FLOWERS_INTERSTITIAL = 'FLOWERS_INTERSTITIAL',
  CANNY_VALLEY_BUDGET = 'CANNY_VALLEY_BUDGET',
  SAMPLE_FLOWER = 'SAMPLE_FLOWER',

  // new updates
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  WEDDING_DETAILS = 'WEDDING_DETAILS',
  MET_BEFORE_AFTER_SUBMITTED = 'MET_BEFORE_AFTER_SUBMITTED',
}

export interface IPlanningStage {
  stage: string
  vendors: IBooleanDynamicPrototype
  intent: string
}

export interface IFlowerWedding {
  partner: string
  bouquets: number
  boutonnieres: number
}

export interface IRitual {
  hasRitual: boolean
  type?: IBooleanDynamicPrototype
}

export interface IWeddingDate {
  weddingDate: string | Date
  fullName: string
  partnerName: string
  emailAddress: string
  phoneNumber: string
  firstName?: string
  lastName?: string
  partnerFirstName?: string
  partnerLastName?: string
}

export type ImagePreferenceOptions = {
  colorPaletteId: number
  id: number
  imageUrl: string
  label: string
}

export type IQuizData = {
  ritual?: IRitual
  planningStage?: IPlanningStage
  floralFocus?: string
  tableType?: IBooleanDynamicPrototype
  location?: ILocationQuiz[]
  budget?: number
  skipServiceability?: boolean
  budgetFocus?: BudgetFocus
  flowerWedding?: IFlowerWedding
  price?: IBooleanDynamicPrototype
  colorPalette?: ColorPalette
  guestCount?: number
  otherInformation?: string
  pinterest?: string
  attributionSource?: string
  weddingDate?: IWeddingDate
  bouquetShape?: string
  quizExperience?: QuizExperience
  experiment?: string[]
  colorPreference?: ColorPreference
  inspirationPhotos?: Inspiration[]
  stemPreferences?: CustomerStemPreference[]
  referralSource?: string
}

export enum UNSERVICEABLE_REASON {
  DATE = 'DATE',
  LOCATION = 'LOCATION',
}

export interface ICustomer {
  fullName?: string
  lastName: string
  firstName: string
  emailAddress: string
  phoneNumber: string
  attributionSource?: string
}

export interface IRecordQuizResponseAPI {
  serviceable?: boolean
  redirectLink?: string
  unserviceableReason?: UNSERVICEABLE_REASON
  validLocation?: boolean
  proposal?: {
    hadProposal: boolean
    proposalUrl?: string
    proposalId?: number
  }
  imageUrl?: string
  totalWeddings?: number
  venueName?: string
  validateError?: {
    email?: boolean
  }
  locationDetail?: {
    city: string
    placeId: string
    state: string
    streetAddress: string
    zipCode: string
  }
}

export interface QuizQuestionData {
  colorPalettes?: ColorPalette[]
  imagePreferenceOptions?: ImagePreferenceOptions[]
  colorPaletteStems?: Stem[]
  budgetContent?: BudgetContent[]
}

export interface QuizQuestionContextData {
  screen: SCREEN_QUIZ_QUESTION | undefined
  data: IQuizData
  recordResponse?: IRecordQuizResponseAPI
  quizQuestionData?: QuizQuestionData
  setRecordResponse: (response?: IRecordQuizResponseAPI) => void
  setQuizScreen: (screen: SCREEN_QUIZ_QUESTION) => void
  setQuizData: (data: IQuizData) => Promise<API<IRecordQuizResponseAPI> | undefined> | null
  setData: (data: IQuizData) => void
  setQuizQuestionData: (data: QuizQuestionData) => void
  redirectUrl?: string
  setRedirectUrl: (url?: string) => void
  quizProgressPercent: number
  setQuizProgressPercent: (percent: number) => void
  weddingDateData: IWeddingDate
  setWeddingDateDataState: (data: IWeddingDate) => void
  referralSource: IOption | null
  setReferralSourceState: (data: IOption | null) => void
}

export const quizQuestionContextDefaultValue: QuizQuestionContextData = {
  screen: SCREEN_QUIZ_QUESTION.WEDDING_DATE,
  data: {},
  quizQuestionData: {},
  setRecordResponse: () => null,
  setQuizData: () => null,
  setQuizScreen: () => null,
  setQuizQuestionData: () => null,
  setData: () => null,
  setRedirectUrl: () => null,
  quizProgressPercent: 0,
  setQuizProgressPercent: () => null,
  weddingDateData: {} as IWeddingDate,
  setWeddingDateDataState: () => null,
  referralSource: null,
  setReferralSourceState: () => null,
}

export const QuizQuestionContext = createContext<QuizQuestionContextData>(quizQuestionContextDefaultValue)
