/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppContext } from 'src/helpers/app'

import { useLocation } from './Location'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../contextAPI'
import { IBooleanDynamicPrototype, IOption, IStringDynamicPrototype } from 'src/types'
import { PATH_URL, REDIRECT_PATH, combineToFullName, getQueryParameters, splitFullName, validate } from 'src/helpers'
import { isValidPhoneNumber } from 'src/common'
import { SegmentAction, segmentIdentify, segmentTracking } from 'src/helpers/segment'
import { QuizExperience, REFERRAL_SOURCES } from 'src/pages/shared'
import { useGetX4FromSession } from 'src/helpers/hooks/useGetX4FromSession'

interface formError {
  firstName?: boolean
  lastName?: boolean
  fullName?: boolean
  partnerName?: boolean
  partnerFirstName?: boolean
  partnerLastName?: boolean
  emailAddress?: boolean
  phoneNumber?: boolean
  location?: boolean
  weddingDate?: boolean
  referralSource?: boolean
}

const segmentTrackingDetail = (experiment: string[]) => {
  if (!experiment.length) {
    segmentTracking(SegmentAction.SQ_DETAILx2_VARIANT_1, {
      quizType: 'CANNY_VALLEY',
      experience: 'No Changes - what’s currently live, all fields required',
      weight: '33%',
    })
    return
  }
  if (experiment.length === 1 && experiment.includes(QuizExperience.ATTRIBUTION_HIDDEN)) {
    segmentTracking(SegmentAction.SQ_DETAILx2_VARIANT_3, {
      quizType: 'CANNY_VALLEY',
      experience: 'Last Name Shown - Last Name Shown',
      weight: '33%',
    })
    return
  }
  segmentTracking(SegmentAction.SQ_DETAILx2_VARIANT_2, {
    quizType: 'CANNY_VALLEY',
    experience: 'Last Names Not Shown - Attribute Question Not Shown',
    weight: '33%',
  })
}

export const useWeddingDate = () => {
  const history = useHistory()
  const x4 = useGetX4FromSession()
  const { sessionID, experiment, setStoreURLParameters } = useAppContext()
  const {
    data,
    setQuizData,
    setQuizScreen,
    setRecordResponse,
    weddingDateData,
    setWeddingDateDataState,
    referralSource,
    setReferralSourceState,
  } = useContext(QuizQuestionContext)
  const location = useLocation()
  const [alreadyBookedSelected] = useState<IBooleanDynamicPrototype>(data.planningStage?.vendors ?? {})
  const [stage, setStage] = useState<string>(data.planningStage?.stage ?? '')
  const [intent] = useState<string>(data.planningStage?.intent ?? '')
  const [focused, setFocused] = useState<IBooleanDynamicPrototype>({})
  const [loading, setLoading] = useState(false)
  // const selectedRef = REFERRAL_SOURCES.find((ref) => ref.value === data?.referralSource)
  // const [referralSource, setReferralSource] = useState<IOption | null>(
  //   data?.referralSource ? { value: data.referralSource, label: selectedRef?.label || '' } : null
  // )
  const [formError, setFormError] = useState<formError>({})

  const isEmailError = useMemo(
    () => !validate.email((weddingDateData.emailAddress ?? '').trim()) || !!formError.emailAddress,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [weddingDateData.emailAddress]
  )
  const isPhoneNumberError = useMemo(() => !isValidPhoneNumber((weddingDateData.phoneNumber ?? '').trim()), [
    weddingDateData.phoneNumber,
  ])
  const isDateValid = useMemo(() => !!weddingDateData.weddingDate, [weddingDateData.weddingDate])
  const isFirstNameError = useMemo(() => !validate.leastOneLetter(weddingDateData.firstName || ''), [
    weddingDateData.firstName,
  ])
  const isLastNameError = useMemo(
    () =>
      !validate.leastOneLetter(weddingDateData.lastName || '') && !experiment.includes(QuizExperience.LAST_NAME_HIDDEN),
    [experiment, weddingDateData.lastName]
  )
  const isPartnerFirstNameError = useMemo(() => !validate.leastOneLetter(weddingDateData.partnerFirstName || ''), [
    weddingDateData.partnerFirstName,
  ])
  const isPartnerLastNameError = useMemo(
    () =>
      !validate.leastOneLetter(weddingDateData.partnerLastName || '') &&
      !experiment.includes(QuizExperience.LAST_NAME_HIDDEN),
    [experiment, weddingDateData.partnerLastName]
  )
  const isReferralSourceError =
    !validate.leastOneLetter(referralSource?.value || '') && !experiment.includes(QuizExperience.ATTRIBUTION_HIDDEN)
  const isStageError = useMemo(() => stage === '', [stage])

  const gTagSetUserData = useCallback((param: any) => {
    const report = (window as { [key: string]: any })['gtag']
    if (report) {
      report('set', 'user_data', param)
    }
  }, [])

  const formInvalidStep1 = () => {
    setFormError({
      firstName: isFirstNameError,
      lastName: isLastNameError,
      emailAddress: isEmailError,
      phoneNumber: isPhoneNumberError,
      referralSource: isReferralSourceError,
    })
    return isFirstNameError || isLastNameError || isEmailError || isPhoneNumberError || isReferralSourceError
  }

  const formInvalid = () => {
    setFormError({
      firstName: isFirstNameError,
      lastName: isLastNameError,
      partnerFirstName: isPartnerFirstNameError,
      partnerLastName: isPartnerLastNameError,
      emailAddress: isEmailError,
      phoneNumber: isPhoneNumberError,
      location: location.nextDisabled,
      weddingDate: !isDateValid,
      referralSource: isReferralSourceError,
    })
    return (
      isFirstNameError ||
      isLastNameError ||
      isPartnerFirstNameError ||
      isPartnerLastNameError ||
      isEmailError ||
      isPhoneNumberError ||
      location.nextDisabled ||
      !isDateValid ||
      isReferralSourceError
    )
  }

  const handleNext = async () => {
    // handleChangeAppData({ isLoading: true })
    if (formInvalidStep1() && !weddingDateData.weddingDate) {
      return
    }
    if (formInvalid() && weddingDateData.weddingDate) {
      return
    }
    setLoading(true)
    const recordResponse = await setQuizData({
      weddingDate: {
        ...weddingDateData,
        fullName: combineToFullName(weddingDateData.firstName, weddingDateData.lastName),
        partnerName: combineToFullName(weddingDateData.partnerFirstName, weddingDateData.partnerLastName),
      },
      location: location.locationSelected,
      planningStage: { vendors: alreadyBookedSelected, stage, intent },
      referralSource: referralSource?.value,
      experiment,
    })

    segmentIdentify(sessionID, {
      name: combineToFullName(weddingDateData.firstName, weddingDateData.lastName),
      email: weddingDateData.emailAddress,
      location: location.locationSelected,
      serviceable: recordResponse?.data.serviceable,
    })
    gTagSetUserData({
      email: weddingDateData.emailAddress,
      phone_number: weddingDateData.phoneNumber,
      address: {
        firstName: weddingDateData.firstName,
        lastName: weddingDateData.lastName,
      },
    })
    // handleChangeAppData({ isLoading: false })
    setLoading(false)
    if (recordResponse?.data) {
      setRecordResponse(recordResponse.data)
      const searchParams = getQueryParameters()

      if (recordResponse.data.validateError?.email) {
        setFormError((data) => ({ ...data, emailAddress: true }))
        return
      }
      if (recordResponse?.data?.proposal?.hadProposal) {
        searchParams?.delete('x4')
        history.push({ pathname: PATH_URL.EXISTING_PROPOSAL, search: searchParams.toString() })
        setQuizScreen(SCREEN_QUIZ_QUESTION.MET_BEFORE)
        return
      }
      if (!weddingDateData.weddingDate) {
        searchParams?.delete('x4')
        history.push({ pathname: PATH_URL.WEDDING_DETAIL, search: searchParams.toString() })
        return
      }
      if (recordResponse.data.serviceable) {
        searchParams?.delete('x4')
        searchParams?.delete('quizData')
        let searchParamsObject: IStringDynamicPrototype = {}
        searchParamsObject = Object.fromEntries(searchParams)
        setStoreURLParameters(searchParamsObject)
        history.push({ pathname: PATH_URL.AVAILABILITY_PAGE, search: searchParams.toString() })
        setQuizScreen(SCREEN_QUIZ_QUESTION.YOU_BOOKED)
      }
    }
  }

  const handleBack = async () => {
    if (window.history.length > 1) {
      history.goBack()
      if (x4) {
        setQuizScreen(SCREEN_QUIZ_QUESTION.PERSONAL_DETAILS)
      }
    } else {
      window.location.href = REDIRECT_PATH.HOME
    }
  }

  const handleDayChangeBigDay = (day: Date) => {
    setWeddingDateDataState({ ...weddingDateData, weddingDate: day })
    setFormError((data) => ({ ...data, weddingDate: false }))
  }

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name === 'fullName') {
      const [firstName, lastName] = splitFullName(value)
      setWeddingDateDataState({ ...weddingDateData, [name]: value, firstName, lastName })
      setFormError((data) => ({ ...data, [name]: false, firstName: false, lastName: false }))
      return
    }
    if (name === 'partnerName') {
      const [partnerFirstName, partnerLastName] = splitFullName(value)
      setWeddingDateDataState({ ...weddingDateData, [name]: value, partnerFirstName, partnerLastName })
      setFormError((data) => ({ ...data, [name]: false, partnerFirstName: false, partnerLastName: false }))
      return
    }
    setWeddingDateDataState({ ...weddingDateData, [name]: value })
    setFormError((data) => ({ ...data, [name]: false }))
  }

  const handleBlurInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target
    setFocused((data) => ({ ...data, [name]: true }))
  }

  const handleChangePhoneNumber = (value: string) => {
    setWeddingDateDataState({ ...weddingDateData, phoneNumber: value })
  }

  const handleBlurPhoneNumber = () => {
    setFocused((data) => ({ ...data, phoneNumber: true }))
  }

  const handleChangeReferralSource = (value: IOption | null) => {
    setReferralSourceState(value)
    setFormError((data) => ({ ...data, referralSource: false }))
  }

  // const handleBlurReferralSource = () => {
  //   setFocused((data) => ({ ...data, referralSource: true }))
  // }

  const handleChangeStage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setStage(value)
  }

  const nextDisabled = useMemo(() => {
    const check =
      !weddingDateData ||
      weddingDateData.weddingDate === undefined ||
      weddingDateData.emailAddress === undefined ||
      weddingDateData.phoneNumber === undefined ||
      location.nextDisabled ||
      isStageError ||
      isReferralSourceError

    return check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStageError, location.nextDisabled, weddingDateData, isReferralSourceError])

  const nextWeddingDetailsDisabled = useMemo(() => {
    const check =
      !weddingDateData ||
      weddingDateData.emailAddress === undefined ||
      weddingDateData.phoneNumber === undefined ||
      isReferralSourceError
    return check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingDateData, isReferralSourceError])

  useEffect(() => {
    setFormError((data) => ({ ...data, location: false }))
  }, [location.locationSelected])

  // The fields are need validate on change
  useEffect(() => {
    setFormError((data) => ({
      ...data,
      phoneNumber: isPhoneNumberError && focused['phoneNumber'],
      emailAddress: isEmailError && focused['emailAddress'],
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhoneNumberError, isEmailError])

  useEffect(() => {
    if (data.weddingDate) {
      const [partnerFirstName, partnerLastName] = splitFullName(data.weddingDate.partnerName.trim())
      setWeddingDateDataState({
        weddingDate: data.weddingDate.weddingDate,
        emailAddress: data.weddingDate.emailAddress,
        phoneNumber: data.weddingDate.phoneNumber,
        firstName: data.weddingDate.firstName || '',
        lastName: data.weddingDate.lastName || '',
        partnerFirstName: partnerFirstName || '',
        partnerLastName: partnerLastName || '',
        fullName: data.weddingDate.fullName.trim(),
        partnerName: data.weddingDate.partnerName,
      })
    }
    if (!stage) {
      setStage(data.planningStage?.stage ?? '')
    }
    if (!referralSource) {
      const selectedRef = REFERRAL_SOURCES.find((ref) => ref.value == data?.referralSource)
      setReferralSourceState(selectedRef ?? null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setWeddingDateDataState])

  useEffect(() => {
    segmentTracking(SegmentAction.SQ_LEGACY_LOADED)
    segmentTrackingDetail(experiment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    data,
    location,
    nextDisabled,
    nextWeddingDetailsDisabled,
    weddingDateData,
    formError,
    handleNext,
    handleBack,
    handleDayChangeBigDay,
    handleChangeInput,
    handleBlurInput,
    handleChangePhoneNumber,
    handleBlurPhoneNumber,
    stage,
    handleChangeStage,
    loading,
    referralSource,
    handleChangeReferralSource,
    // handleBlurReferralSource,
    experiment,
  }
}
